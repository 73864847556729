<template>
    <div class="login_main">
        <div class="header">
            <img src="../../assets/img/shop_logo.png" class="logo" @click="$router.replace('/index')" />
        </div>

        <div class="main">
            <div class="content">
                <img :src="backendImage ? imageURL + backendImage : require('../../assets/img/login_img.png')" class="img" />

                <div class="form">
                    <div class="tab">
                        <div :class="loginType === 1 ? 'on' : ''" class="item" @click="loginType = 1">密码登录</div>
                        <div :class="loginType === 2 ? 'on' : ''" class="item" @click="loginType = 2">免密登录</div>
                    </div>
                    <form v-if="loginType === 1">
                        <div class="formItem">
                            <div class="inputItem">
                                <div class="icon">
                                    <img src="../../assets/img/login_user.png" width="16" height="20" />
                                </div>
                                <input v-model="form.userName" autocomplete="username" type="text" placeholder="请输你的手机号码" class="formInput" @keyup.enter="goLogin" />
                            </div>
                        </div>

                        <div class="formItem">
                            <div class="inputItem">
                                <div class="icon">
                                    <img src="../../assets/img/login_password.png" width="16" height="20" />
                                </div>
                                <input v-model="form.password" autocomplete="pwd" type="password" placeholder="请输入密码" class="formInput" @keyup.enter="goLogin" />
                            </div>
                        </div>

                        <div class="formItem">
                            <el-checkbox v-model="checked">记住用户名</el-checkbox>
                        </div>

                        <div class="formItem">
                            <el-button type="primary" class="loginBtn" :loading="isRequestLoading" @click="goLogin">立即登录</el-button>
                        </div>
                    </form>

                    <form v-if="loginType === 2">
                        <div class="formItem">
                            <div class="inputItem">
                                <div class="icon">
                                    <img src="../../assets/img/login_phone.png" width="16" height="20" />
                                </div>
                                <input v-model="form.phone" autocomplete="phone" placeholder="请输入您的手机号" class="formInput" @keyup.enter="goVerifyCode" />
                            </div>
                        </div>

                        <div class="formItem">
                            <div class="inputItem">
                                <div class="icon">
                                    <img src="../../assets/img/login_code.png" width="16" height="20" />
                                </div>
                                <input v-model="form.imgCode" autocomplete="imgCode" placeholder="请输入图形验证码" class="formInput" @keyup.enter="goVerifyCode" />
                            </div>
                            <img :src="codeImage" class="codeBtn" @click="goImgCode" style="cursor: pointer" />
                        </div>

                        <div class="formItem">
                            <div class="inputItem">
                                <div class="icon">
                                    <img src="../../assets/img/login_code.png" width="16" height="20" />
                                </div>
                                <input v-model="form.code" autocomplete="code" placeholder="请输入验证码" class="formInput" @keyup.enter="goVerifyCode" />
                            </div>
                            <el-button :class="isSendCode ? 'on' : ''" class="codeBtn" :loading="isRequestSend" @click="toSendCode">{{isSendCode ? smsBtnText : '获取验证码'}}</el-button>
                        </div>

                        <div class="formItem mt49">
                            <el-button type="primary" class="loginBtn" :loading="isRequestLoading" @click="goVerifyCode">立即登录</el-button>
                        </div>
                    </form>

                    <div class="else">
                        <div class="item" @click="$router.replace('/forget')">忘记密码</div>
                        <div class="item" @click="$router.replace('/register')">免费注册</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import {login, smsSend, smsVerify, postImgCode, getBackendLogin} from '../../api/public'
  import {regular_phone} from "../../utils/regular";
  import {baseURL, imageURL} from '../../utils/config'
  export default {
    name: "Login",
    data () {
      return {
        imageURL: imageURL,
        form: {
          userName: '',
          password: '',
          phone: '',
          imgCode: '',
          code: ''
        },
        loginType: 1,
        isRequestLoading: false,
        isRequestSend: false,
        isSendCode: false,
        checked: false,
        smsBtnText: '',
        codeNum: 0,
        codeImage: '',
        dateTimes: null,
        backendImage: ''
      }
    },
    created() {
      this.dateTimes = new Date().getTime();
      this.goImgCode();
      this.getBackendLogin();
      if (localStorage.getItem('isShowUserNameShop') && localStorage.getItem('isShowUserNameShop') === 'yes') {
        this.checked = true
        this.form.userName = localStorage.getItem('userNameShop')
      }
    },
    methods: {
      goImgCode () {
        this.codeNum++
        this.codeImage = `${baseURL}/kaptcha/getImageCode/${this.dateTimes}?type=${this.codeNum}`
      },

      getBackendLogin () {
        getBackendLogin().then(res => {
          if (res.code === 0) {
            this.backendImage = res.data.fileName
          }
        })
      },

      toSendCode () {
        if (this.isSendCode) {
          return false
        }
        if (this.form.phone === '') {
          this.$message({
            message: '请输入您的手机号',
            showClose: true,
            type: 'warning'
          })
          return false
        }

        if (this.form.imgCode === '') {
          this.$message({
            message: '请输入图形验证码',
            showClose: true,
            type: 'warning'
          })
          return false
        }

        if (!regular_phone.test(this.form.phone)) {
          this.$message({
            message: '请输入正确的手机号',
            showClose: true,
            type: 'warning'
          })
          return false
        }

        postImgCode({
          data: {
            code: this.form.imgCode,
            uuid: this.dateTimes
          }
        }).then(res => {
          if (res.code === 0) {
            this.getCode()
          } else {
            this.goImgCode()
          }
        }).catch(() => {
          this.goImgCode()
        })
      },

      getCode () {
        this.isRequestSend = true
        smsSend({
          data: {
            phone: this.form.phone,
            type: 3
          }
        }).then(res => {
          this.isRequestSend = false
          if(res.code === 0) {
            this.isSendCode = true
            this.toChangeSmsTime(60)
          }
        }).catch(() => {
          this.isRequestSend = false
        })
      },

      toChangeSmsTime (time) {
        if (time === 0) {
          this.isSendCode = false
        } else {
          this.isSendCode = true
          this.smsBtnText = '重新获取(' + time + 's)'
          let times = time - 1
          let that = this
          setTimeout(() => {
            that.toChangeSmsTime(times)
          }, 1000)
        }
      },

      goVerifyCode () {
        if (this.form.phone === '') {
          this.$message({
            message: '请输入您的手机号',
            showClose: true,
            type: 'warning'
          })
          return false
        }

        if (this.form.imgCode === '') {
          this.$message({
            message: '请输入图形验证码',
            showClose: true,
            type: 'warning'
          })
          return false
        }

        if (this.form.code === '') {
          this.$message({
            message: '请输入验证码',
            showClose: true,
            type: 'warning'
          })
          return false
        }
        this.isRequestLoading = true
        smsVerify({
          data: {
            phone: this.form.phone,
            type: 3,
            code: this.form.code
          }
        }).then(res => {
          if (res.code === 0) {
            this.goLogin()
          } else {
            this.isRequestLoading = false
          }
        }).catch(() => {
          this.isRequestLoading = false
        })
      },

      goLogin () {
        if (this.loginType === 1) {
          if (this.form.userName === '') {
            this.$message({
              message: '请输入手机号',
              type: 'warning',
              duration: 2 * 1000
            })
            return false
          }
          if (this.form.password === '') {
            this.$message({
              message: '请输入密码',
              type: 'warning',
              duration: 2 * 1000
            })
            return false
          }
        }

        if (this.loginType === 2) {
          if (this.form.phone === '') {
            this.$message({
              message: '请输入您的手机号',
              type: 'warning',
              duration: 2 * 1000
            })
            return false
          }
          if (this.form.code === '') {
            this.$message({
              message: '请输入验证码',
              type: 'warning',
              duration: 2 * 1000
            })
            return false
          }
        }
        this.isRequestLoading = true
        let data = {}
        if (this.loginType === 1) {
          data = {
            // client_id: 'shop',
            // client_secret: 'shop',
            grant_type: 'shop',
            userName: this.form.userName,
            password: this.form.password,
            loginType: 1
          }
        }
        if (this.loginType === 2) {
          data = {
            // client_id: 'shop',
            // client_secret: 'shop',
            grant_type: 'shop',
            phone: this.form.phone,
            // code: this.form.code,
            loginType: 2
          }
        }
        const formBody = Object.keys(data).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]),).join('&');
        login(formBody).then(res => {
          this.isRequestLoading = false
          if (res.access_token) {
            localStorage.setItem('access_token_shop', res.access_token)
            localStorage.setItem('userNameShop', this.form.userName)
            if (sessionStorage.getItem('url')) {
              this.$router.replace(sessionStorage.getItem('url'))
              return false
            } else {
              this.$router.replace('/index')
            }
            if (this.checked) {
              localStorage.setItem('isShowUserNameShop', 'yes')
            } else {
              localStorage.removeItem('isShowUserNameShop')
            }
          }
        }).catch(() => {
          this.isRequestLoading = false
        })
      },
    }
  }
</script>
